import { API, graphqlOperation } from "aws-amplify";

import { PackageBom } from "API";
import { getPackageBom } from "graphql/queries";

export const getPackageBomByID = async (
  packageTypeId: string
): Promise<any> => {
  const p = (
    await API.graphql(
      graphqlOperation(getPackageBom, { input: { packageTypeId } })
    )
  ).data.getPackageBom;
  return p;
};
