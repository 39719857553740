import { useReducer, Reducer, useEffect, useState } from "react";
import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";
import { useAlerts } from "contexts/alerts";

interface GraphQLVariables {
  [key: string]: any;
}

export interface GraphQLInput {
  id?: string;
  [key: string]: any;
}

interface QueryProps {
  query: string;
  variables?: GraphQLVariables | undefined;
  sort?: string | ((a: any, b: any) => void);
}

export interface LoadOptions {
  progress: boolean;
}

export interface UpdateOptions {
  // DBに更新だけして、stateの更新は行わない
  dbOnly: boolean;
}

interface QueryState {
  data: any;
  loading: boolean;
  creating: boolean;
  updating: boolean;
  removing: boolean;
  nextToken: string | null;
  error: string | boolean;
  loadNext: () => null;
  appendNext: () => null;
  create: (mutation: string, input: GraphQLInput) => void;
  update: (
    mutation: string,
    input: GraphQLInput,
    options?: UpdateOptions
  ) => void;
  remove: (mutation: string, input: GraphQLInput) => void;
  refetch: (customVariables?: GraphQLVariables, options?: LoadOptions) => null;
}

interface QueryAction {
  type: string;
  payload?: any;
}

interface GraphQLResult {
  data: {
    [key: string]: {
      items: object[];
      nextToken?: string;
    };
  };
  errors: any;
}

const initialState = {
  data: [],
  loading: false,
  creating: false,
  updating: false,
  removing: false,
  error: false,
  nextToken: null,
  refetch: () => null,
  loadNext: () => null,
  appendNext: () => null,
  create: () => null,
  update: () => null,
  remove: () => null,
  set: () => null,
};

const reducer: Reducer<QueryState, QueryAction> = (
  state: QueryState,
  action: QueryAction
) => {
  let newData = state.data;
  switch (action.type) {
    case "LOAD_DATA":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "DATA_LOADED":
      let result = action.payload.data;
      if (action.payload.sort) {
        if (typeof action.payload.sort === "string") {
          result = result.sort(
            (a: any, b: any) => a[action.payload.sort] - b[action.payload.sort]
          );
        } else {
          // custom sort
          result = result.sort(action.payload.sort);
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        data: result,
        nextToken: action.payload.nextToken,
      };
    case "DATA_LOADED_AND_APPEND":
      let appendData = action.payload.data;
      let allData = [...state.data, ...appendData];
      if (action.payload.sort) {
        if (typeof action.payload.sort === "string") {
          allData = allData.sort(
            (a: any, b: any) => a[action.payload.sort] - b[action.payload.sort]
          );
        } else {
          // custom sort
          allData = allData.sort(action.payload.sort);
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        data: allData,
        nextToken: action.payload.nextToken,
      };
    case "SET_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case "CREATE_DATA":
      return {
        ...state,
        creating: true,
        error: false,
      };
    case "DATA_CREATED":
      // state.data[].idがaction.payload.data.idと同じ場合は上書きする
      let appendedData = [...state.data, action.payload.data];
      if (
        state.data.some((d: GraphQLInput) => d.id === action.payload.data.id)
      ) {
        appendedData = state.data.map((d: GraphQLInput) =>
          d.id === action.payload.data.id ? action.payload.data : d
        );
      }
      return {
        ...state,
        creating: false,
        error: false,
        data: appendedData,
      };
    case "UPDATE_DATA":
      let targetIdx = state.data.findIndex(
        (d: GraphQLInput) => d.id === action.payload.data.id
      );
      newData[targetIdx] = { ...newData[targetIdx], ...action.payload.data };

      return {
        ...state,
        updating: true,
        error: false,
        data: newData,
      };
    case "DATA_UPDATED":
      newData[
        state.data.findIndex(
          (d: GraphQLInput) => d.id === action.payload.data.id
        )
      ] = action.payload.data;

      return {
        ...state,
        updating: false,
        error: false,
        data: newData,
      };
    case "SET_DATA":
      return {
        ...state,
        data: action.payload.data,
      };
    case "DATA_SET":
      return {
        ...state,
        updating: false,
        error: false,
        data: newData,
      };
    case "DELETE_DATA":
      return {
        ...state,
        deleting: true,
        error: false,
      };
    case "DATA_DELETED":
      return {
        ...state,
        deleting: false,
        error: false,
        data: state.data.filter((d: any) => d.id !== action.payload.data.id),
      };
    default:
      return initialState;
  }
};

const loadOptions = {
  progress: true,
};

export default function useDatalist({ query, variables, sort }: QueryProps) {
  const [result, dispatch] = useReducer(reducer, initialState);
  const [customVariables, setCustomVariables] = useState<
    GraphQLVariables | undefined
  >(undefined);
  const { addAlert } = useAlerts();

  // triggers data fetch when initialized
  useEffect(() => {
    loadData(variables, loadOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async (
    gqlVariables: GraphQLVariables | undefined,
    options: LoadOptions | undefined = loadOptions
  ) => {
    try {
      if (!!options.progress) {
        dispatch({ type: "LOAD_DATA" });
      }
      const res = (await API.graphql(
        graphqlOperation(_.get(queries, query), gqlVariables)
      )) as GraphQLResult;

      if (res.errors)
        return dispatch({ type: "SET_ERROR", payload: res.errors });

      const { items, nextToken } = res.data[query];

      dispatch({
        type: "DATA_LOADED",
        payload: { data: items, nextToken, sort },
      });
    } catch (err) {
      if (typeof err === "string") {
        dispatch({ type: "SET_ERROR", payload: err });
      } else if (err instanceof Error) {
        dispatch({ type: "SET_ERROR", payload: err.message });
      }
      throw err;
    }
  };

  const loadNext = async () => {
    try {
      const res = (await API.graphql(
        graphqlOperation(_.get(queries, query), {
          ...variables,
          nextToken: result.nextToken,
        })
      )) as GraphQLResult;

      if (res.errors)
        return dispatch({ type: "SET_ERROR", payload: res.errors });

      const { items, nextToken } = res.data[query];

      dispatch({
        type: "DATA_LOADED",
        payload: { data: items, nextToken, sort },
      });
    } catch (err: any) {
      if (typeof err === "string") {
        dispatch({ type: "SET_ERROR", payload: err });
      } else if (err instanceof Error) {
        dispatch({ type: "SET_ERROR", payload: err.message });
      } else if (err.errors !== undefined) {
        addAlert({ message: err.errors[0].message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.errors[0].message });
      }
      throw err;
    }
  };

  const appendNext = async () => {
    if (result.nextToken === null)
      return dispatch({ type: "SET_ERROR", payload: "no more data" });
    try {
      const res = (await API.graphql(
        graphqlOperation(_.get(queries, query), {
          ...variables,
          ...customVariables,
          nextToken: result.nextToken,
        })
      )) as GraphQLResult;

      if (res.errors)
        return dispatch({ type: "SET_ERROR", payload: res.errors });

      const { items, nextToken } = res.data[query];

      dispatch({
        type: "DATA_LOADED_AND_APPEND",
        payload: { data: items, nextToken, sort },
      });
    } catch (err: any) {
      if (typeof err === "string") {
        dispatch({ type: "SET_ERROR", payload: err });
      } else if (err instanceof Error) {
        dispatch({ type: "SET_ERROR", payload: err.message });
      } else if (err.errors !== undefined) {
        addAlert({ message: err.errors[0].message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.errors[0].message });
      }
      throw err;
    }
  };

  const create = async (mutation: string, input: GraphQLInput | undefined) => {
    try {
      dispatch({ type: "CREATE_DATA" });
      const res = (await API.graphql(
        graphqlOperation(_.get(mutations, mutation), {
          input,
        })
      )) as GraphQLResult;

      if (!res.data[mutation]) throw Error("Data not created");

      dispatch({ type: "DATA_CREATED", payload: { data: res.data[mutation] } });
      addAlert({ message: "作成されました", severity: "success" });
      return res.data[mutation];
    } catch (err: any) {
      if (typeof err === "string") {
        addAlert({ message: err, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err });
      } else if (err instanceof Error) {
        addAlert({ message: err.message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.message });
      } else if (err.errors !== undefined) {
        addAlert({ message: err.errors[0].message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.errors[0].message });
      }
      throw err;
    }
  };

  const update = async (
    mutation: string,
    input: GraphQLInput | undefined,
    options?: UpdateOptions
  ) => {
    try {
      dispatch({ type: "UPDATE_DATA", payload: { data: input } });
      const res = (await API.graphql(
        graphqlOperation(_.get(mutations, mutation), {
          input,
        })
      )) as GraphQLResult;

      if (options?.dbOnly) {
        return res.data[mutation];
      }

      dispatch({ type: "DATA_UPDATED", payload: { data: res.data[mutation] } });
      addAlert({
        message: "データが更新されました",
        severity: "success",
      });
      return res.data[mutation];
    } catch (err: any) {
      if (typeof err === "string") {
        addAlert({ message: err, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err });
      } else if (err instanceof Error) {
        addAlert({ message: err.message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.message });
      } else if (err.errors !== undefined) {
        addAlert({ message: err.errors[0].message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.errors[0].message });
      }
      throw err;
    }
  };

  const set = async (input: GraphQLInput | undefined) => {
    try {
      dispatch({ type: "SET_DATA", payload: { data: input } });
      dispatch({ type: "DATA_SET" });
      return;
    } catch (err: any) {
      if (typeof err === "string") {
        addAlert({ message: err, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err });
      } else if (err instanceof Error) {
        addAlert({ message: err.message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.message });
      } else if (err.errors !== undefined) {
        addAlert({ message: err.errors[0].message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.errors[0].message });
      }
      throw err;
    }
  };

  const remove = async (mutation: string, input: GraphQLInput | undefined) => {
    try {
      dispatch({ type: "DELETE_DATA" });
      const res = (await API.graphql(
        graphqlOperation(_.get(mutations, mutation), {
          input,
        })
      )) as GraphQLResult;
      dispatch({ type: "DATA_DELETED", payload: { data: res.data[mutation] } });
      addAlert({ message: "データが削除されました", severity: "success" });
      return res.data[mutation];
    } catch (err: any) {
      if (typeof err === "string") {
        addAlert({ message: err, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err });
      } else if (err instanceof Error) {
        addAlert({ message: err.message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.message });
      } else if (err.errors !== undefined) {
        addAlert({ message: err.errors[0].message, severity: "error" });
        dispatch({ type: "SET_ERROR", payload: err.errors[0].message });
      }
      throw err;
    }
  };

  // refetch the same dataset
  // can refetch with new variables
  const refetch = async (
    customVariables: GraphQLVariables | undefined = undefined,
    options?: LoadOptions
  ) => {
    setCustomVariables(customVariables);
    await loadData(customVariables || variables, options);
  };

  return {
    ...result,
    refetch,
    loadNext,
    appendNext,
    update,
    create,
    remove,
    set,
  };
}
