import { extractElementValue } from "./extract";
import { Bom, ComponentBom, DecorationBom } from "./update";

import * as Z1402 from "utils/jis/combinationSign/Z1402";
import * as Z1403 from "utils/jis/combinationSign/Z1403";
import * as Z1410Level1 from "utils/jis/combinationSign/Z1410Level1";
import * as Z1410Level2 from "utils/jis/combinationSign/Z1410Level2";
import * as Z1410Level3 from "utils/jis/combinationSign/Z1410Level3";
import * as Z1410Level4 from "utils/jis/combinationSign/Z1410Level4";
import * as beamSupportMaterial from "utils/jis/beamSupportMaterial";
import * as beamMaterial from "utils/jis/beamMaterial";
import { Element, Material, PackageType, Product, QuoteProduct } from "API";
import { calcM3 } from "utils/product";

/**
 * Extracts slug from variables in expression
 * @param  {string} variable i.e. '\@\[長さ](productLength)'
 * @returns {string} i.e. 'productLength'
 */
const extractSlug = (variable: string): string =>
  variable.replace(/@\[(.*?)]\(/g, "").replace(/\)/g, "");

/**
 * Extracts id from materials in expression
 * @param  {string} variable i.e. '#[薫蒸木材 チリ松 120x120](8acc8f8e-e01c-4aa2-b4df-dbbf4ee224ab)'
 * @returns {string} i.e. '8acc8f8e-e01c-4aa2-b4df-dbbf4ee224ab'
 */
const extractId = (variable: string): string =>
  variable.replace(/#\[(.*?)]\(/g, "").replace(/\)/g, "");

/**
 * Extracts variables within the expression, and returns if it exists in slugs
 * @param  {string} expr expression in cell i.e. '= \@\[長さ](productLength) + 100'
 * @param  {string} slugs all avaialble slugs i.e. ['productLength', 'productWidth', ...]
 * @returns {string[]} variables(slugs) extracted from expression
 */
const extractVariables = (expr: string, slugs: string[]): string[] | null => {
  expr = expr.replace(/[!=&|<>]/g, " + ");
  expr = expr.replace(/if\s/gi, " + ");
  expr = expr.replace(/\selse\s/gi, " + ");
  // expr = expr.replace(/Math.+?\(*\)/gi, ' + ');
  const exprs = expr.split(/[+\-*/%,]/);

  const variables = exprs.reduce((r: any, e: any) => {
    const matches = e.match(/@\[(.*?)]\([.\w]+\)/g);
    if (matches) r.push(matches[0]);
    return r;
  }, []);

  if (!variables) return null;
  // check if the variable is available in slugs
  if (variables.filter((v: any) => !slugs.includes(extractSlug(v))).length > 0)
    return null;

  return variables;
};

/**
 * Extracts material ids within the expression
 * @param  {string} expr expression in cell i.e. '= #[薫蒸木材 チリ松 120x120](8acc8f8e-e01c-4aa2-b4df-dbbf4ee224ab)'
 * @returns {string[]} material ids extracted from expression
 */
const extractMaterials = (expr: string): string[] => {
  expr = expr.replace(/[!=&|<>]/g, " + ");
  expr = expr.replace(/if/gi, " + ");
  expr = expr.replace(/else/gi, " + ");
  const exprs = expr.split(/[\s}][+\-*/%][\s{]/);
  const materials = exprs.reduce((r: any, e: any) => {
    const matches = e.match(/#\[(.*?)]\([\s\S]+\)/g);
    if (matches) r.push({ var: matches[0], id: extractId(matches[0]) });
    return r;
  }, []);
  if (!materials) return [];

  return materials;
};

/**
 * Calculates element value based on expression
 * @param  {object} elements { slug: element[] }
 * @param  {object} element target element data with expression
 * @param  {string[]} slugs all available slugs
 * @param  {object[]} result { slug: value }[] - results from previous calculation
 * @param  {function} materialOverwrite overwrite mateiral dimensions to elements
 * @returns {object[]} { slug: value }[] - appends new slug-value pair to previous result, returns null as value if unapplicable
 */
const calculateValue = (
  elements: any,
  result: any,
  element: any,
  slugs: any,
  materialOverwrite: any,
  materials: any,
  species: any,
  mainSpeciesId: any,
  mainSpeciesEdgeMPa: any,
  mainSpeciesFaceMPa: any
) => {
  const { slug, expr } = element;
  const vars = extractVariables(expr, slugs);

  if (!vars) return { ...result, [slug]: null };

  // create new expr with value inside
  let newExpr = expr.replace("=", "").replace("materialValue", "");
  vars.forEach((variable: any) => {
    const vSlug = extractSlug(variable);
    const varEl = flattenElements(elements).filter(
      (e: any) => e.slug === vSlug
    )[0];
    if (!(vSlug in result)) {
      result = extractValues(
        elements,
        result,
        varEl,
        slugs,
        materialOverwrite,
        materials,
        species,
        mainSpeciesId,
        mainSpeciesEdgeMPa,
        mainSpeciesFaceMPa
      );
    }
    if (varEl.type === "string") {
      newExpr = newExpr.replace(variable, `'${result[vSlug]}'`);
    } else {
      newExpr = newExpr.replace(variable, result[vSlug]);
    }
  });

  // materials
  const extMaterials = extractMaterials(expr);
  extMaterials.forEach((m: any) => {
    newExpr = newExpr.replace(m.var, `'${m.id}'`);
  });

  try {
    // evalで使う可能性があるので定義しておく
    const Z1402calcCombinationSign = Z1402.calcCombinationSign;
    const Z1403calcCombinationSign = Z1403.calcCombinationSign;
    const Z1410Level1calcCombinationSign = Z1410Level1.calcCombinationSign;
    const Z1410Level2calcCombinationSign = Z1410Level2.calcCombinationSign;
    const Z1410Level3calcCombinationSign = Z1410Level3.calcCombinationSign;
    const Z1410Level4calcCombinationSign = Z1410Level4.calcCombinationSign;
    const JISBeamSupportMaterial = beamSupportMaterial.JISBeamSupportMaterial;
    const JISBeamMaterial = beamMaterial.JISBeamMaterial;
    const JISBeamEdge = beamMaterial.JISBeamEdge;
    // eslint-disable-next-line no-eval
    const newVal = eval(newExpr);
    if (element.type === "string" && slug.includes("materialId")) {
      //* update elements from material
      materialOverwrite(newVal, element);
    }
    // eslint-disable-next-line valid-typeof
    result[slug] = newVal;
    // result[slug] = typeof newVal === ('number' || 'string') ? newVal : null;
  } catch (err) {
    // ReferenceErrorだったらvalueを入れてあげる
    if (err instanceof ReferenceError) {
      result[slug] = element?.value;
    } else {
      result[slug] = null;
    }
  }

  return result;
};

/**
 * Extract element value when expression is null
 * @param  {object} elements { slug: element[] }
 * @param  {object} element target element data with expression
 * @param  {string[]} slugs all available slugs
 * @param  {object[]} result { slug: value }[] results from previous calculation
 * @param  {function} materialOverwrite overwrite mateiral dimensions to elements
 * @returns {object[]} { slug: value }[], appends new slug-value pair to previous result, returns null as value if unapplicable
 */
const extractValues = (
  elements: any,
  result: any,
  element: any,
  slugs: any,
  materialOverwrite: any,
  materials: any,
  species: any,
  mainSpeciesId: any,
  mainSpeciesEdgeMPa: any,
  mainSpeciesFaceMPa: any
) => {
  const { slug, expr, value, overwriteValue } = element;

  if ((!expr && value) || overwriteValue !== null) {
    result[slug] = value;
  } else if (!expr) {
    result[slug] = null;
  } else {
    result = calculateValue(
      elements,
      result,
      element,
      slugs,
      materialOverwrite,
      materials,
      species,
      mainSpeciesId,
      mainSpeciesEdgeMPa,
      mainSpeciesFaceMPa
    );
  }

  return result;
};

/**
 * flatten slug-elements object into elements array
 * @param  {object} elements { slug: element[] }
 * @returns {object[]} element[] - flattened array of elements
 */
export const flattenElements = (elements: any) => {
  let flattened: any = [];

  Object.keys(elements).forEach((key) => {
    flattened = flattened.concat(elements[key]);
  });

  return flattened;
};

/**
 * @typedef {object} AllElements
 * @property {object} elements { slug: elements[] } all elements
 * @property {string[]} slugs all available slugs
 */
const getAllElements = (
  product: any,
  cmps: any,
  interiors: any,
  exteriors: any
) => {
  const elements: any = {};
  elements.product = Object.values(product).map((x) => x);
  Object.entries(cmps).forEach(([id, el]: any) => {
    elements[id] = Object.values(el).map((x) => x);
  });
  Object.entries(interiors).forEach(([id, el]: any) => {
    elements[id] = Object.values(el).map((x) => x);
  });
  Object.entries(exteriors).forEach(([id, el]: any) => {
    elements[id] = Object.values(el).map((x) => x);
  });
  return elements;
};

/**
 * Gets all elements from package and components form methods
 * @param  {object} product package form methods
 * @param  {object} cmps { slug: component form methods }
 * @returns {AllElements} { elements, slugs } - all available elements and slugs
 */
const getElements = (
  product: any,
  cmps: any,
  interiors: any,
  exteriors: any
) => {
  const elements = getAllElements(product, cmps, interiors, exteriors);
  const slugs = Object.values(elements).reduce((r: any, c: any) => {
    r = r.concat(c.map((element: any) => element.slug));
    return r;
  }, []);
  return { elements, slugs };
};

/**
 * Set all updated values for all package's & components' elements
 * @param  {object[]} variables { slug: value }
 * @param  {object} elements { slug: element[] }
 * @param  {object} product package form methods
 * @param  {object} cmps { slug: component form methods }
 */
const setElements = (
  variables: any,
  elements: any,
  cmps: any,
  setProductElements: any,
  setCmpElements: any,
  interiorElements: any,
  setInteriorElements: any,
  exteriorElements: any,
  setExteriorElements: any
) => {
  //* loop through product elements
  const newProduct = elements.product.reduce((r: any, element: any) => {
    // product.setValue(`elements[${idx}].value`, variables[element.slug]);
    r[element.slug] = { ...element, value: variables[element.slug] };
    return r;
  }, {});
  setProductElements(newProduct);
  //* loop through all components elements
  const newCmps = Object.entries(cmps).reduce(
    (result: any, [id, els]: [id: any, els: any]) => {
      result[id] = Object.values(els).reduce((r: any, el: any) => {
        r[el.slug] = { ...el, value: variables[el.slug] };
        return r;
      }, {});
      return result;
    },
    {}
  );
  setCmpElements(newCmps);

  const newInteriors = Object.entries(interiorElements).reduce(
    (result: any, [id, els]: [id: any, els: any]) => {
      result[id] = Object.values(els).reduce((r: any, el: any) => {
        r[el.slug] = { ...el, value: variables[el.slug] };
        return r;
      }, {});
      return result;
    },
    {}
  );
  setInteriorElements(newInteriors);

  const newExteriors = Object.entries(exteriorElements).reduce(
    (result: any, [id, els]: [id: any, els: any]) => {
      result[id] = Object.values(els).reduce((r: any, el: any) => {
        r[el.slug] = { ...el, value: variables[el.slug] };
        return r;
      }, {});
      return result;
    },
    {}
  );
  setExteriorElements(newExteriors);
};

/**
 * Update all elements which has a bining material dimension, except overwritten values
 * @param  {object} material material information with dimension
 * @param  {string} slug element's slug with component slug attached
 * @param  {function overwriteElementValue(slug, value) {

 }} overwriteElementValue function that updates specific element
 */
const overwriteElementsByMaterial = (
  material: any,
  slug: any,
  overwriteElementValue: any
) => {
  const componentSlug = slug.split(".")[0];
  if (!material) {
    overwriteElementValue(`${componentSlug}.length`, null);
    overwriteElementValue(`${componentSlug}.lengthNominal`, null);
    overwriteElementValue(`${componentSlug}.width`, null);
    overwriteElementValue(`${componentSlug}.widthNominal`, null);
    overwriteElementValue(`${componentSlug}.height`, null);
    overwriteElementValue(`${componentSlug}.heightNominal`, null);
  }
  if (material) {
    // materialIdを上書き
    // 基本的には変更にはならないが、JISBeamMaterialを使っている場合は発生する
    if (material.id) {
      overwriteElementValue(`${componentSlug}.materialId`, material.id);
    }
    if (material.lengthLocked) {
      // find index of element and update
      overwriteElementValue(`${componentSlug}.length`, material.length);
      overwriteElementValue(
        `${componentSlug}.lengthNominal`,
        material.lengthNominal
      );
    } else {
      overwriteElementValue(`${componentSlug}.length`, null);
      overwriteElementValue(`${componentSlug}.lengthNominal`, null);
    }
    if (material.widthLocked) {
      overwriteElementValue(`${componentSlug}.width`, material.width);
      overwriteElementValue(
        `${componentSlug}.widthNominal`,
        material.widthNominal
      );
    } else {
      overwriteElementValue(`${componentSlug}.width`, null);
      overwriteElementValue(`${componentSlug}.widthNominal`, null);
    }
    if (material.heightLocked) {
      overwriteElementValue(`${componentSlug}.height`, material.height);
      overwriteElementValue(
        `${componentSlug}.heightNominal`,
        material.heightNominal
      );
    } else {
      overwriteElementValue(`${componentSlug}.height`, null);
      overwriteElementValue(`${componentSlug}.heightNominal`, null);
    }
  }
};

/**
 * Update all elements with calculated values from expression
 * @param  {object} productElements package form methods
 * @param  {object} cmpElements { slug: component form methods }
 */
export const calculateElements = (
  productElements: any,
  cmpElements: any,
  setProductElements: any,
  setCmpElements: any,
  materials: any,
  interiorElements: any,
  exteriorElements: any,
  setInteriorElements: any,
  setExteriorElements: any,
  bomData: any,
  species: any,
  mainSpeciesId: any,
  mainSpeciesEdgeMPa: any,
  mainSpeciesFaceMPa: any
) => {
  const { elements, slugs } = getElements(
    productElements,
    cmpElements,
    interiorElements,
    exteriorElements
  );
  // edgeの以前の値
  let variablesForEdgeBefore: any = {};
  for (const component of bomData?.components) {
    const edgeValue = component?.elements?.edge?.value ?? 0;
    variablesForEdgeBefore[`${component.slug}.edge`] =
      parseInt(edgeValue, 10) || 0;
  }
  // valueはmaterialの値
  const overwriteElementValue = (slug: any, value: any) => {
    let idx = flatElements.findIndex((x: any) => x.slug === slug);
    if (idx === -1) return;

    // materialIdはvalueの上書きのみ。
    // 基本的にMaterialIDの上書きは入力として行われるので、この処理はいらない(入力ではない場合も後でvariablesで上書きするから問題ない)。
    // ただ、materialIdに式が入っていて、それでサイズ関連が変わる場合に反映されないことがあるので、ここで更新する必要がある
    if (slug.endsWith(".materialId")) {
      flatElements[idx].value = value;
      return;
    }

    // width, height, widthNominal, heightNominalは
    // 1. 今反転はしないけど、すでに反転状態の場合はslugを反転させていidxを更新する(資材変更などの考慮)
    if (slug.includes(".width") || slug.includes(".height")) {
      // 同Elementのedgeを持ってくる
      const edgeSlug = slug.split(".")[0] + ".edge";
      const edgeIdx = flatElements.findIndex((x: any) => x.slug === edgeSlug);
      if (edgeIdx !== -1) {
        const beforeValue = variablesForEdgeBefore[edgeSlug];
        const afterValue =
          parseInt(flatElements[edgeIdx].value ?? "0", 10) ?? 0;
        // 今反転はしないけど、すでに反転状態の場合は対象のslugを反転させる
        if (beforeValue === afterValue && afterValue !== 0) {
          // 反転する
          if (slug.includes(".width")) {
            slug = slug.replace(".width", ".height");
            idx = flatElements.findIndex((x: any) => x.slug === slug);
          } else if (slug.includes(".height")) {
            slug = slug.replace(".height", ".width");
            idx = flatElements.findIndex((x: any) => x.slug === slug);
          }
        }
      }
    }
    if (idx === -1) return;

    // if material value is null, and no overwriteValue, append null
    if (
      value === null &&
      !flatElements[idx].overwriteValue &&
      flatElements[idx].expr
    ) {
      flatElements[idx].value = null;
      if (!flatElements[idx].expr) return;
      // if material value appended to expression, clear expression
      const exprIsMaterial = flatElements[idx].expr.includes("materialValue");
      if (exprIsMaterial) flatElements[idx].expr = "";
    }
    // if material value is null and overwrite exists, return
    if (!value) return;
    // add material value as expression
    flatElements[idx].expr = `= materialValue(${value})`;
    // if current value is null, append material value
    if (flatElements[idx].value === null)
      flatElements[idx].value = value ? value.toString() : value;
    // if (
    // flatElements[idx].value != value &&
    //! !flatElements[idx].overwriteValue
    // ) {
    // if value not the same as material value, and no overwriteValue, add overwriteValue
    // flatElements[idx].overwriteValue = value ? value.toString() : value;
    // }
    if (flatElements[idx].value == value) {
      // if value is the same as material value, remove overwriteValue
      flatElements[idx].overwriteValue = null;
      flatElements[idx].value = value ? value.toString() : value;
    }
  };

  let flatElements = flattenElements(elements);

  // exprを利用していない部材のmaterialIdを更新
  (slugs as any).forEach((slug: any) => {
    if (!slug.includes("materialId")) return;
    const materialId = flatElements.filter((x: any) => x.slug === slug)[0]
      .value;
    const material = materials.filter((x: any) => x.id === materialId)[0];
    overwriteElementsByMaterial(material, slug, overwriteElementValue);
  });

  const materialOverwrite = (materialId: any, element: any) => {
    const material = materials.filter((x: any) => x.id === materialId)[0];
    overwriteElementsByMaterial(material, element.slug, overwriteElementValue);
  };

  // ここから各elementの評価を行う、順序は以下
  // 1. materialId(materialIdに式がある場合先に評価する必要がある)
  // 2. JISBeamMaterial(Edgeの設定とMaterialの設定が同時に起きた場合はMaterialを先に評価する必要があるため)
  // 3. edge(必要ならこの後の計算で巾高反転した値を使いたいため)
  // 4. panelM2とwoodM3を使っていない式(panelM2, woodM3の計算のために必要なものを先に計算)
  // 5. panelM2とwoodM3を使っている式

  const variablesForMaterialIds = flatElements.reduce(
    (result: any, element: any) => {
      if (!element.slug?.includes(".materialId")) {
        return result;
      }
      return extractValues(
        elements,
        result,
        element,
        slugs,
        materialOverwrite,
        materials,
        species,
        mainSpeciesId,
        mainSpeciesEdgeMPa,
        mainSpeciesFaceMPa
      );
    },
    {}
  );

  // JISBeamMaterialを使っているelementの評価を先に行う
  const variablesForJisBeamMaterial = flatElements.reduce(
    (result: any, element: any) => {
      if (!element.expr?.includes("JISBeamMaterial")) {
        return result;
      }
      return extractValues(
        elements,
        result,
        element,
        slugs,
        materialOverwrite,
        materials,
        species,
        mainSpeciesId,
        mainSpeciesEdgeMPa,
        mainSpeciesFaceMPa
      );
    },
    {}
  );

  // edgeの評価を先に行う
  const variablesForEdge = flatElements.reduce((result: any, element: any) => {
    if (
      !element.slug?.endsWith(".edge") ||
      element.expr?.includes("JISBeamMaterial")
    ) {
      return result;
    }
    return extractValues(
      elements,
      result,
      element,
      slugs,
      materialOverwrite,
      materials,
      species,
      mainSpeciesId,
      mainSpeciesEdgeMPa,
      mainSpeciesFaceMPa
    );
  }, {});

  // edgeが0→0以外 or 0以外→0になる部材については、該当するmaterialを参考に巾と高さを反転する
  // flatElementsの反転
  flatElements = invertFlattenElements(
    flatElements,
    materials,
    variablesForEdge,
    variablesForEdgeBefore
  );

  // elementsを反転
  const invElements = invertElementMap(
    elements,
    materials,
    variablesForEdge,
    variablesForEdgeBefore
  );

  // component関連でこのあと参照する変数(cmpElements)を反転
  let invCmpElements = invertCmpElement(
    cmpElements,
    materials,
    variablesForEdge,
    variablesForEdgeBefore
  );

  //* map values to slugs
  // まずpanelM2とwoodM3を使っていない式を計算
  const variablesFirst = flatElements.reduce((result: any, element: any) => {
    if (
      element.expr?.includes("@[panelM2]") ||
      element.expr?.includes("@[woodM3]") ||
      element.slug?.endsWith(".edge") ||
      element.expr?.includes("JISBeamMaterial")
    ) {
      return result;
    }
    return extractValues(
      invElements,
      result,
      element,
      slugs,
      materialOverwrite,
      materials,
      species,
      mainSpeciesId,
      mainSpeciesEdgeMPa,
      mainSpeciesFaceMPa
    );
  }, {});
  // その結果からcomponentだけ持ってくる
  const newCmps = Object.entries(invCmpElements).reduce(
    (result: any, [id, els]: [id: any, els: any]) => {
      result.push({
        id: id,
        elements: Object.values(els).map((el: any) => {
          return { ...el, value: variablesFirst[el.slug] };
        }),
      });
      return result;
    },
    []
  );
  // ↑を使ってwoodM3とpanelM2を計算
  const woodM3 = calculateWoodM3(newCmps);
  const panelM2 = calculatePanelM2(newCmps);

  // 計算時にpanelM2とwoodM3を含める必要があるので一時的に追加
  invElements.product.push({
    cutting: null,
    description: null,
    expr: null,
    name: "panelM2",
    overwriteValue: null,
    slug: "panelM2",
    type: "number",
    value: panelM2,
  });
  invElements.product.push({
    cutting: null,
    description: null,
    expr: null,
    name: "woodM3",
    overwriteValue: null,
    slug: "woodM3",
    type: "number",
    value: woodM3,
  });
  (slugs as any).push("woodM3");
  (slugs as any).push("panelM2");
  // panelM2とwoodM3を使っている式を計算
  const variables = flatElements.reduce((result: any, element: any) => {
    if (
      (!element.expr?.includes("@[panelM2]") &&
        !element.expr?.includes("@[woodM3]")) ||
      element.slug?.endsWith(".edge") ||
      element.expr?.includes("JISBeamMaterial")
    ) {
      return result;
    }
    return extractValues(
      invElements,
      result,
      element,
      slugs,
      materialOverwrite,
      materials,
      species,
      mainSpeciesId,
      mainSpeciesEdgeMPa,
      mainSpeciesFaceMPa
    );
  }, {});
  delete variables.panelM2;
  delete variables.woodM3;
  const newElements = {
    ...invElements,
    product: invElements.product.filter(
      (elem: any) => elem.slug !== "panelM2" && elem.slug !== "woodM3"
    ),
  };

  //* set value for package and components
  setElements(
    {
      ...variablesForMaterialIds,
      ...variablesForJisBeamMaterial,
      ...variablesForEdge,
      ...variablesFirst,
      ...variables,
    },
    newElements,
    invCmpElements,
    setProductElements,
    setCmpElements,
    interiorElements,
    setInteriorElements,
    exteriorElements,
    setExteriorElements
  );
};

// Cost計算を行う関数
// input:
// - product: Product
// - bom: ProductBom
// - materials: Material[]
// return:
// - woodenTotal(木材合計): product.material === "熱処理木材" で bomのcomponentの中で資材分類が木材のものの「原価合計(totalCost)」の合計
// - lvlTotal(LVL合計): product.material === "LVL" で bomのcomponentの中で資材分類が木材のものの「原価合計」の合計
// - plywoodTotal(合板合計): bomのcomponentの中で資材分類が合板のものの「原価合計」の合計
// - interiorTotal(内装合計): bom.interiorsの「原価合計」の合計
// - exteriorTotal(外装合計): bom.exteriorsの「原価合計」の合計
// - otherTotal(その他合計): (未開発)人件費や釘など内外装以外で原価となるものの合計
// - totalCost(合計): woodenTotal + lvlTotal + plywoodTotal + interiorTotal + exteriorTotal + otherTotal
// - m3Cost(m3原価): totalCost / (梱包のm3) を小数点以下繰り上げしたもの
export type CostType =
  | "woodenTotal"
  | "lvlTotal"
  | "plywoodTotal"
  | "interiorTotal"
  | "exteriorTotal"
  | "otherTotal"
  | "m3Cost"
  | "totalCost";

type CostInfo = {
  [K in CostType]: number;
};

export const calculateProductCost = (
  product: PackageType | QuoteProduct | Product | null | undefined,
  bom: Bom | null | undefined,
  materials: Material[]
): CostInfo => {
  if (!product || !bom) {
    return {} as CostInfo;
  }
  // woodenTotal or lvlTotalを先に求める
  // まずは資材分類が木材のcomponentの原価合計(totalCost)の合計を計算
  let total = 0;
  if (bom.components !== undefined) {
    total = _sumComponentsTotalCost(bom.components, materials, "木材");
  }

  // 熱処理木材かLVLかで分岐
  let woodenTotal = 0;
  let lvlTotal = 0;
  if (product.material === "熱処理木材") {
    woodenTotal = total;
  } else if (product.material === "LVL") {
    lvlTotal = total;
  }

  // plywoodTotal(合板合計)を計算
  let plywoodTotal = 0;
  if (bom.components !== undefined) {
    plywoodTotal = _sumComponentsTotalCost(bom.components, materials, "合板");
  }

  // interiorTotal(内装合計)を計算
  let interiorTotal = 0;
  if (bom.interiors !== undefined) {
    interiorTotal = _sumComponentsTotalCost(bom.interiors, materials);
  }

  // exteriorTotal(外装合計)を計算
  let exteriorTotal = 0;
  if (bom.exteriors !== undefined) {
    exteriorTotal = _sumComponentsTotalCost(bom.exteriors, materials);
  }

  // otherTotal(その他原価)を取得
  let otherTotal = 0;
  if (bom.product !== undefined) {
    otherTotal = parseInt(bom.product.elements?.otherCost?.value ?? "0", 10);
  }

  // totalCost(合計)を計算
  let totalCost =
    woodenTotal +
    lvlTotal +
    plywoodTotal +
    interiorTotal +
    exteriorTotal +
    otherTotal;

  // m3Cost(m3原価)を計算
  const m3 = calcM3(
    parseFloat(bom?.product?.elements?.outerLength?.value ?? "0") ?? 0,
    parseFloat(bom?.product?.elements?.outerWidth?.value ?? "0") ?? 0,
    parseFloat(bom?.product?.elements?.outerHeight?.value ?? "0") ?? 0
  );
  const m3Cost = Math.ceil(totalCost / m3);

  return {
    woodenTotal: woodenTotal,
    lvlTotal: lvlTotal,
    plywoodTotal: plywoodTotal,
    interiorTotal: interiorTotal,
    exteriorTotal: exteriorTotal,
    otherTotal: otherTotal,
    totalCost: totalCost,
    m3Cost: m3Cost,
  };
};

// components, decorationsに対して、資材分類が指定したのものの「原価合計」の合計を計算する関数
// materialTypeNameが空だったら全ての資材分類のものを合計する
const _sumComponentsTotalCost = (
  components: (ComponentBom | DecorationBom)[],
  materials: Material[],
  materialTypeName: string = ""
): number => {
  return components
    .filter((component) => {
      // componentに該当するmaterialを取得
      const material = materials.find(
        (material) => material.id === component.elements.materialId.value
      );
      // materialなかったらスキップ
      if (material === undefined) return false;
      // materialのmaterialTypeが空だったらスキップ
      if (material.materialType === null || material.materialType === undefined)
        return false;

      // 資材分類がmaterialTypeNameのものだけ抽出
      // 空文字だったら全て持ってくる
      return (
        materialTypeName === "" ||
        material?.materialType.name === materialTypeName
      );
    })
    .reduce((acc, component) => {
      if (
        component.elements === undefined ||
        component.elements === null ||
        component.elements.totalCost === undefined ||
        component.elements.totalCost === null
      )
        return acc;
      const total = parseInt(component.elements.totalCost.value ?? "0", 10);
      return acc + (isNaN(total) ? 0 : total);
    }, 0);
};

export const calculatePanelM2 = (components: any): number => {
  let panelM2 = 0;
  components.forEach((cpt: any) => {
    const m2 = extractElementValue(cpt.elements, "m2");
    panelM2 += m2;
  });
  return Math.round(panelM2 * 100000) / 100000;
};

export const calculateWoodM3 = (components: any): number => {
  let woodM3 = 0;
  components.forEach((cpt: any) => {
    const m3 = extractElementValue(cpt.elements, "m3");
    woodM3 += m3;
  });
  return Math.round(woodM3 * 100000) / 100000;
};

export const calculateGW = (productElements: any, tareWeight: number) => {
  let grossWeight = 0;

  const netWeight = extractElementValue(productElements, "NW");

  // GrossWeight-tareWeight=NetWeight
  grossWeight = parseFloat(netWeight) + tareWeight;

  return Math.round(grossWeight * 100000) / 100000;
};

const invertCmpElement = (
  cmpElements: any,
  materials: Material[],
  edgeVariables: { [key: string]: number }, // { "slug.edge": value }
  edgeVariablesBefore: { [key: string]: number } // { "slug.edge": value }
): any => {
  const cmpOnlyElements: { [key: string]: any[] } = {};
  Object.entries(cmpElements).forEach(([id, el]: any) => {
    cmpOnlyElements[id] = Object.values(el).map((x) => x);
  });
  const invCmpElementsMap = invertElementMap(
    cmpOnlyElements,
    materials,
    edgeVariables,
    edgeVariablesBefore
  );
  let invCmpElements: any = {};
  Object.entries(invCmpElementsMap).forEach(([id, values]: any) => {
    let el: any = {};
    values.forEach((value: any) => {
      el[value.slug] = value;
    });
    invCmpElements[id] = el;
  });
  return invCmpElements;
};

// elementsでedgeが0以外のものを巾高反転する
const invertElementMap = (
  elements: { [key: string]: Element[] },
  materials: Material[],
  edgeVariables: { [key: string]: number }, // { "slug.edge": value }
  edgeVariablesBefore: { [key: string]: number } // { "slug.edge": value }
): any => {
  let invertedElements = { ...elements };

  // 反転する対象の取得
  const invertComponentSlugs = pickInvertTargets(
    edgeVariables,
    edgeVariablesBefore
  );

  // invertComponentSlugsに該当するComponentをMaterialを元に反転
  for (const { slug, reverse } of invertComponentSlugs) {
    // elementsからvalueのslugに`slug`が含まれるvalueを取得する
    let targetKey = "";
    let targetElements: Element[] = [];
    for (const [key, element] of Object.entries(elements)) {
      const slugContainElem = element.filter((e) =>
        e.slug.startsWith(`${slug}.`)
      );
      if (slugContainElem && slugContainElem.length > 0) {
        targetKey = key;
        targetElements = element;
        break;
      }
    }

    // その中からmaterialId取得
    const materialId = targetElements.find((element) =>
      element.slug?.endsWith(".materialId")
    )?.value;

    // material持ってくる
    const material = materials.find((material) => material.id === materialId);

    if (!material) continue;

    // 反転
    const inverted = invertElement(targetElements, material, slug, reverse);

    // 今回のtargetKeyを持つ要素をinvertedElementsから排除
    delete invertedElements[targetKey];
    // invertedElementsにinvertedを追加
    invertedElements[targetKey] = inverted;
  }

  return invertedElements;
};

// flattenElementsでedgeが0以外のものを巾高反転する
const invertFlattenElements = (
  flattenElements: Element[],
  materials: Material[],
  edgeVariables: { [key: string]: number }, // { "slug.edge": value }
  edgeVariablesBefore: { [key: string]: number } // { "slug.edge": value }
): any => {
  let invertedFlattenElements = [...flattenElements];

  // 反転する対象の取得
  const invertComponentSlugs = pickInvertTargets(
    edgeVariables,
    edgeVariablesBefore
  );

  // invertComponentSlugsに該当するComponentをMaterialを元に反転
  for (const { slug, reverse } of invertComponentSlugs) {
    // 対象のelementを取得
    const elements = flattenElements.filter((element) =>
      element.slug?.startsWith(slug)
    );

    // その中からmaterialId取得
    const materialId = elements.find((element) =>
      element.slug?.endsWith(".materialId")
    )?.value;

    // material持ってくる
    const material = materials.find((material) => material.id === materialId);

    if (!material) continue;

    // 反転
    const invertedElements = invertElement(elements, material, slug, reverse);

    // 今回のslugを含むslugを持つelementをflattenElementsから削除し、invertedElementsを追加
    invertedFlattenElements = invertedFlattenElements.filter(
      (element) => !element.slug?.startsWith(slug)
    );
    invertedFlattenElements.push(...invertedElements);
  }

  return invertedFlattenElements;
};

// 反転処理に回すべきかどうかを判定する関数
const pickInvertTargets = (
  edgeVariables: { [key: string]: number | string | null },
  edgeVariablesBefore: { [key: string]: number }
): { slug: string; reverse: boolean }[] => {
  const invertComponentSlugs = Object.entries(edgeVariables)
    .filter(([slug, value]) => {
      // まずは反転する対象のslugを抽出
      if (!slug.endsWith(".edge")) return false;
      const beforeValue = edgeVariablesBefore[slug] ?? 0;
      const afterValue =
        typeof value === "string" ? parseInt(value) : value ?? 0;
      // 以前と今回で値が変わっていてどっちかが0だったらtrue(1→0 or 0→1)
      return (
        afterValue !== beforeValue && (afterValue === 0 || beforeValue === 0)
      );
    })
    .map(([slug, value]) => {
      // 抽出したものに対して0→0以外(反転)か、0以外→0(反転の反転)かの情報を付与
      const afterValue =
        typeof value === "string" ? parseInt(value) : value ?? 0;
      // 2つ目の値は反転(0→1)してたらfalse、反転の反転(1→0)だったらtrue
      const reverse = afterValue === 0;
      return { slug: slug.split(".")[0], reverse };
    });
  return invertComponentSlugs;
};

// 実際にmaterialの値を元に巾高さを反転する関数
// materialから該当の部材の情報を取得し、それと現在入力されている値を元に巾高さを反転する
// reverseがtrueの場合は反転の反転の場合で、この時は↑で使うmaterialの情報を逆にする必要がある
type ElementParts = Pick<Element, "expr" | "value" | "overwriteValue">;
const invertElement = (
  elements: Element[],
  material: Material,
  slugPrefix: string,
  reverse: boolean // edgeが0->0以外(幅高反転)の時はfalse, 0以外->0(幅高反転の反転)の時はtrue
): Element[] => {
  let invertedElements = elements;
  // 呼び寸じゃないほう反転
  // 情報取得
  const [width, height] = pickUpElement(elements, material, false, reverse);

  // 変更前のもの持っておく
  const beforeWidth = invertedElements.find(
    (i) => i.slug === `${slugPrefix}.width`
  );
  const beforeHeight = invertedElements.find(
    (i) => i.slug === `${slugPrefix}.height`
  );
  if (width && height && beforeWidth && beforeHeight) {
    // 一回排除してから追加する
    invertedElements = invertedElements.filter(
      (i) =>
        i.slug !== `${slugPrefix}.width` && i.slug !== `${slugPrefix}.height`
    );
    // 反転して更新
    invertedElements.push(
      {
        ...beforeWidth,
        value: height.value,
        expr: height.expr,
        overwriteValue: height.overwriteValue,
      },
      {
        ...beforeHeight,
        value: width.value,
        expr: width.expr,
        overwriteValue: width.overwriteValue,
      }
    );
  }

  // 呼び寸反転
  const [widthNominal, heightNominal] = pickUpElement(
    elements,
    material,
    true,
    reverse
  );
  const beforeWidthNominal = invertedElements.find(
    (i) => i.slug === `${slugPrefix}.widthNominal`
  );
  const beforeHeightNominal = invertedElements.find(
    (i) => i.slug === `${slugPrefix}.heightNominal`
  );
  if (
    widthNominal &&
    heightNominal &&
    beforeWidthNominal &&
    beforeHeightNominal
  ) {
    invertedElements = invertedElements.filter(
      (i) =>
        i.slug !== `${slugPrefix}.widthNominal` &&
        i.slug !== `${slugPrefix}.heightNominal`
    );
    invertedElements.push(
      {
        ...beforeWidthNominal,
        value: heightNominal.value,
        expr: heightNominal.expr,
        overwriteValue: heightNominal.overwriteValue,
      },
      {
        ...beforeHeightNominal,
        value: widthNominal.value,
        expr: widthNominal.expr,
        overwriteValue: widthNominal.overwriteValue,
      }
    );
  }
  return invertedElements;
};

// Element群から該当するmaterialのwidthとheightを取り出す関数(もしもElementの値が上書きされていたらそれを使う)
// isNomialを指定すると呼び寸の方を取得する
// reverseをtrueに指定すると元とするmaterialのwidthとheightを逆にする(反転後の反転では元とするmaterialのfieldが逆になるため)
const pickUpElement = (
  elements: Element[],
  material: Material,
  isNominal: boolean,
  reverse: boolean // これをtrueにするとベースとするMaterialのwidthとheightの情報を逆にする(0→1の時はfalse)
): [ElementParts | null, ElementParts | null] => {
  const keys: ("widthNominal" | "heightNominal" | "width" | "height")[] =
    isNominal ? ["widthNominal", "heightNominal"] : ["width", "height"];
  const materialKeys = reverse ? keys.slice().reverse() : keys;

  const result: ElementParts[] = [];

  for (const key of keys) {
    const materialKey = materialKeys[keys.indexOf(key)];
    let value: ElementParts = {
      value: material[materialKey]?.toString() || null,
      expr: material[materialKey]
        ? `= materialValue(${material[materialKey]})`
        : null,
      overwriteValue: null,
    };
    if (isEditedMaterialValue(elements, key)) {
      const element = elements.find((e) => e.slug.endsWith(`.${key}`));
      if (element) {
        value = {
          value: element.value,
          expr: element.expr,
          overwriteValue: element.overwriteValue,
        };
      }
    }
    result.push(value);
  }
  if (result.length !== 2) {
    return [null, null];
  }
  return [result[0], result[1]];
};

// targetとしているelementの値がmaterialから更新されたものか判定する
// 判定基準としては、overwriteValueが存在するか
const isEditedMaterialValue = (
  elements: Element[],
  target: "width" | "height" | "widthNominal" | "heightNominal"
): boolean => {
  const key = target;
  const element = elements.find((e) => e.slug.endsWith(`.${key}`));
  if (!element) return false;
  return !!element.overwriteValue;
};
