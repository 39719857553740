import {
  extractProductElements,
  extractComponentsElements,
  extractDecorationsElements,
} from "./extract";
import {
  calculateElements,
  calculateGW,
  calculatePanelM2,
  calculateProductCost,
  calculateWoodM3,
} from "./calculate";
import { parseBom } from "./parse";
import { getMaterial, getValue, isExpr } from "./elements";
import {
  getProductBomByProductId,
  updatePackageBom,
  updateProductBom,
} from "./operation";

import { Group, Material, Species } from "API";
import { GraphQLInput } from "hooks/data";
import {
  Product,
  PackageType,
  Element,
  Component,
  DecorationComponent,
} from "API";
import {
  calcM3,
  getProductById,
  getUpdatedCaseInputByProductElements,
  updateProduct,
} from "utils/product";
import { getPackageBomByID } from "utils/packageBom";
import { getPackageById } from "utils/package";

type Modify<T, R> = Omit<T, keyof R> & R;

interface ElementsObject {
  [key: string]: Element;
}

type ProductBom = Modify<
  Product,
  {
    __typename: any;
    elements: ElementsObject;
  }
>;

type PackageBom = Modify<
  PackageType,
  {
    __typename: any;
    elements: ElementsObject;
  }
>;

export type ComponentBom = Modify<
  Component,
  {
    areaId: string;
    areaName: string;
    areaOrder: number;
    order: number;
    elements: ElementsObject;
  }
>;

export type DecorationBom = Modify<
  DecorationComponent,
  {
    elements: ElementsObject;
  }
>;

export interface Bom {
  product?: ProductBom | PackageBom;
  components?: ComponentBom[];
  interiors?: DecorationBom[];
  exteriors?: DecorationBom[];
}

interface ElementInput {
  id: string;
  name?: string;
  unit?: string;
  woodM3?: number;
  panelM2?: number;
  grossWeight?: number;
  elements?: Element[];
  woodCost?: number;
  lvlCost?: number;
  panelCost?: number;
  interiorCost?: number;
  exteriorCost?: number;
  m3Cost?: number;
  totalCost?: number;
}

interface UpdateBomInput {
  product?: ElementInput;
  components?: ElementInput[];
  decorations?: ElementInput[];
}

//input UpdateProductBomInput {
//product: UpdateBomInput
//components: [UpdateBomInput]
//decorations: [UpdateBomInput]
//}
//
function updateElements(
  elements: ElementsObject | undefined,
  inputs: GraphQLInput | undefined
) {
  // return as is when no input
  if (!elements || !inputs) return Object.values(elements as any);
  // get slugs
  const inputKeys = Object.keys(inputs).filter((i) => i !== "id");
  return Object.keys(elements).reduce((result: Element[], key: string) => {
    const element: Element = elements[key];
    // return as is for other elements
    if (!inputKeys.includes(key)) return [...result, element];
    // 入力がexprの場合
    if (isExpr(inputs[key])) {
      // expr更新して次へ
      result.push({
        ...element,
        expr: inputs[key],
        overwriteValue: null,
      });
      return result;
    }
    // initiate element
    let overwriteValue = null;

    if (element.expr && inputs[key] !== null) {
      if (element.value === null) {
        // PAX-588 元々の数式がnullの場合、入力値を採用
        overwriteValue = 0; // PAX-588 フォント色がyellowにするため0に設定
      } else if (inputs[key] == element.value) {
        // 入力と元の値が同じなら上書きしない(set→update時にoverwriteValueまで上書きされるため)
        // ==で比較しているのは、入力値が文字列で元の値が数値の場合があるため
        overwriteValue = element.overwriteValue as any;
      } else {
        overwriteValue = element.value as any;
      }
    }

    let field = {
      value: inputs[key],
      overwriteValue: overwriteValue,
    };

    if (inputKeys.includes(key))
      result.push({
        ...element,
        ...field,
      });

    return result;
  }, []) as Element[];
}

// add cost elements to components
const calculateComponentCost = (
  elements: any,
  materials: Material[]
): Element[] => {
  const material = getMaterial(materials, elements);
  if (!material) return elements;
  const slug = elements[0].slug.split(".")[0];
  const quantity = parseFloat(getValue(elements, "quantity"));
  let amount = quantity;
  const m2 = parseFloat(getValue(elements, "m2"));
  const m3 = parseFloat(getValue(elements, "m3"));
  if (material.unit === "m2") amount = m2;
  if (material.unit === "m3") amount = m3;
  const totalCost =
    (material.defaultUnitCost || 0) * (amount * (1 + (material.lossRate || 0)));
  // elementsにすでに${slug}.totalCost, ${slug}.unitCost, ${slug}.lossRateがある場合は上書き
  const newElements = elements.filter(
    (el: Element) =>
      ![`${slug}.totalCost`, `${slug}.unitCost`, `${slug}.lossRate`].includes(
        el.slug
      )
  );
  return [
    ...newElements,
    {
      slug: `${slug}.totalCost`,
      name: "原価合計",
      type: "number",
      value: totalCost.toFixed(0),
    },
    {
      slug: `${slug}.unitCost`,
      name: "原価",
      type: "number",
      value: material.defaultUnitCost,
    },
    {
      slug: `${slug}.lossRate`,
      name: "ロス率",
      type: "number",
      value: material.lossRate,
    },
  ];
};

const updateComponentElements = (
  id: string,
  components: ComponentBom[] | undefined,
  materials: Material[],
  inputs?: GraphQLInput
) => {
  if (!components) return [];
  // return updated elements for specific component
  const component = components.filter((c) => c.id === id)[0];
  if (!component || !component.elements) return [];
  let updatedComponentElements;
  if (!inputs) updatedComponentElements = Object.values(component.elements);
  if (!!inputs)
    updatedComponentElements = updateElements(component.elements, inputs);

  updatedComponentElements = calculateComponentCost(
    updatedComponentElements,
    materials
  );

  return updatedComponentElements;
};

// add cost elements to decorations
const calculateDecorationCost = (
  elements: any,
  materials: Material[]
): Element[] => {
  const material = getMaterial(materials, elements);
  if (!material) return elements;
  const slug = elements[0].slug.split(".")[0];
  const quantity = parseFloat(getValue(elements, "quantity"));
  let amount = quantity;
  const totalCost =
    (material.defaultUnitCost || 0) * (amount * (1 + (material.lossRate || 0)));
  return [
    ...elements,
    {
      slug: `${slug}.totalCost`,
      name: "原価合計",
      type: "number",
      value: totalCost.toFixed(0),
    },
    {
      slug: `${slug}.unitCost`,
      name: "原価",
      type: "number",
      value: material.defaultUnitCost,
    },
    {
      slug: `${slug}.lossRate`,
      name: "ロス率",
      type: "number",
      value: material.lossRate,
    },
  ];
};

const updateDecorationElements = (
  id: string,
  interiors: DecorationBom[] | undefined,
  exteriors: DecorationBom[] | undefined,
  materials: Material[],
  inputs?: GraphQLInput
) => {
  if (!interiors || !exteriors) return [];
  // return updated elements for specific decoration
  const decorations = [...interiors, ...exteriors];
  const decoration = decorations.filter((c) => c.id === id)[0];
  if (!decoration || !decoration.elements) return [];
  let updatedDecorationElements;
  if (!inputs) updatedDecorationElements = Object.values(decoration.elements);
  if (!!inputs)
    updatedDecorationElements = updateElements(decoration.elements, inputs);

  updatedDecorationElements = calculateDecorationCost(
    updatedDecorationElements,
    materials
  );

  return updatedDecorationElements;
};

export function updateBomElements(
  rawData: Bom | undefined,
  inputs: GraphQLInput,
  materials: Material[],
  species: Species[],
  group: Group | undefined
): UpdateBomOutput {
  const data = parseBom(rawData);
  if (!rawData || !data)
    return { updatedBom: undefined, updatableBom: undefined };

  // rawDataはBom型ではない
  let updatedBom: any = rawData;
  let updatableBom: UpdateBomInput = {
    product: undefined,
    components: [],
    decorations: [],
  };

  // update product elements if inputs.id is equal to product.id
  const runUpdateProductElements =
    inputs.id && data.product?.id && inputs.id === data.product.id;
  if (runUpdateProductElements) {
    // updateする場合はupdateElementsを呼んでUpdateする
    Object.assign(updatableBom, {
      product: {
        id: inputs.id,
        elements: updateElements(data.product?.elements, inputs),
      },
    });
  } else {
    // updateしない場合は更新を空振りさせる
    Object.assign(updatableBom, {
      product: {
        id: data.product?.id,
        elements: updateElements(data.product?.elements, { id: inputs.id }),
      },
    });
  }

  // update component elements
  updatableBom.components = data.components?.map((component) => {
    if (inputs.id === component.id)
      return {
        id: component.id,
        elements: updateComponentElements(
          component.id,
          data.components,
          materials,
          inputs
        ),
      } as ElementInput;
    return {
      id: component.id,
      elements: updateComponentElements(
        component.id,
        data.components,
        materials
      ),
    } as ElementInput;
  });

  // update interior elements
  let interiors = data.interiors?.map((int) => {
    if (inputs.id === int.id) {
      return {
        id: inputs.id,
        elements: updateDecorationElements(
          int.id,
          data.interiors,
          data.exteriors,
          materials,
          inputs
        ),
      } as ElementInput;
    }
    return {
      id: int.id,
      elements: updateDecorationElements(
        int.id,
        data.interiors,
        data.exteriors,
        materials
      ),
    } as ElementInput;
  }) as ElementInput[];

  // update exterior elements
  let exteriors = data.exteriors?.map((ext) => {
    if (inputs.id === ext.id) {
      return {
        id: inputs.id,
        elements: updateDecorationElements(
          ext.id,
          data.interiors,
          data.exteriors,
          materials,
          inputs
        ),
      } as ElementInput;
    }
    return {
      id: ext.id,
      elements: updateDecorationElements(
        ext.id,
        data.interiors,
        data.exteriors,
        materials
      ),
    } as ElementInput;
  }) as ElementInput[];

  let pkg = undefined;
  let cmp: any[] = [];
  let int: any = [];
  let ext: any = [];
  let pkgElements = extractProductElements(updatableBom.product);
  let cmpElements = extractComponentsElements(updatableBom.components);
  let interiorElements = extractDecorationsElements(interiors);
  let exteriorElements = extractDecorationsElements(exteriors);

  calculateElements(
    pkgElements,
    cmpElements,
    (newPkg: any) => {
      pkg = Object.values(newPkg);
    },
    (newCmp: any) => {
      cmp = Object.entries(newCmp).map(([id, el]) => ({
        id,
        elements: Object.values(el as any),
      }));
    },
    materials,
    interiorElements,
    exteriorElements,
    (newInt: any) => {
      int = Object.entries(newInt).map(([id, el]) => ({
        id,
        elements: Object.values(el as any),
      }));
    },
    (newExt: any) => {
      ext = Object.entries(newExt).map(([id, el]) => ({
        id,
        elements: Object.values(el as any),
      }));
    },
    data,
    species,
    group?.mainSpeciesId ?? "",
    group?.mainSpeciesEdgeMPa ?? "",
    group?.mainSpeciesFaceMPa ?? ""
  );

  // productのpanelM2とwoodM3を計算する
  if (cmp && updatableBom.product) {
    updatableBom.product.woodM3 = calculateWoodM3(cmp);
    updatableBom.product.panelM2 = calculatePanelM2(cmp);
  }

  updatableBom.product = {
    id: data.product?.id as string,
    panelM2: updatableBom.product?.panelM2,
    woodM3: updatableBom.product?.woodM3,
    grossWeight: calculateGW(
      pkg,
      (pkg as any).find((p: any) => p.slug === "tareWeight")?.value ?? 0
    ),
    elements: pkg,
  };
  // materialIdなど更新した後でtotalCostの再計算必要なので再計算実施
  updatableBom.components = cmp.map((component) => {
    return {
      id: component.id,
      elements: updateComponentElements(component.id, cmp, materials),
    } as ElementInput;
  });
  const updatableInt = int.map((i: any) => {
    return {
      id: i.id,
      elements: updateDecorationElements(i.id, int, ext, materials),
    } as ElementInput;
  }) as ElementInput[];
  const updatableExt = ext.map((e: any) => {
    return {
      id: e.id,
      elements: updateDecorationElements(e.id, int, ext, materials),
    } as ElementInput;
  }) as ElementInput[];
  updatableBom.decorations = [...updatableInt, ...updatableExt];

  // updatableBomをもとにupdatedBomを更新
  // まずはproduct.elementsを更新(updatableBomのproduct以下は全て情報があるのでそのままでOK)
  const newProductElements = updatableBom.product?.elements?.reduce(
    (result: any, element: any) => {
      result[element.slug] = element;
      return result;
    },
    {}
  );
  updatedBom.product.elements = JSON.stringify(newProductElements);
  updatedBom.product.panelM2 = updatableBom.product.panelM2;
  updatedBom.product.woodM3 = updatableBom.product.woodM3;
  updatedBom.product.grossWeight = updatableBom.product.grossWeight;

  // 次にcomponents.elementsを更新(updatableBomのcomponents以下はelementsしかないので注意)
  const newComponents = updatedBom.components.map((c: any) => {
    const componentToUpdate = updatableBom.components?.filter(
      (component) => component.id === c.id
    )[0];
    const newElements = componentToUpdate?.elements?.reduce(
      (result: any, element: any) => {
        // slugに.が含まれていたら.以降
        let slug = element.slug;
        if (element.slug.includes(".")) slug = element.slug.split(".")[1];
        result[slug] = element;
        return result;
      },
      {}
    );
    return {
      ...c,
      elements: JSON.stringify(newElements),
    };
  });
  updatedBom.components = newComponents;

  // interiorとexteriorについても同様
  const newInt = updatedBom.interiors?.map((interior: any) => {
    const intToUpdate: any = updatableInt.filter((i: any) => {
      return i.id === interior.id;
    })[0];
    const newElements = intToUpdate?.elements.reduce(
      (result: any, element: any) => {
        // slugに.が含まれていたら.以降
        let slug = element.slug;
        if (element.slug.includes(".")) slug = element.slug.split(".")[1];
        result[slug] = element;
        return result;
      },
      {}
    );
    return {
      ...interior,
      elements: JSON.stringify(newElements),
    };
  });
  const newExt = updatedBom.exteriors?.map((exterior: any) => {
    const extToUpdate: any = updatableExt.filter((e: any) => {
      return e.id === exterior.id;
    })[0];
    const newElements = extToUpdate?.elements.reduce(
      (result: any, element: any) => {
        // slugに.が含まれていたら.以降
        let slug = element.slug;
        if (element.slug.includes(".")) slug = element.slug.split(".")[1];
        result[slug] = element;
        return result;
      },
      {}
    );
    return {
      ...exterior,
      elements: JSON.stringify(newElements),
    };
  });
  updatedBom.exteriors = newExt;
  updatedBom.interiors = newInt;

  // 原価計算
  const info = calculateProductCost(
    updatedBom.product,
    parseBom(updatedBom),
    materials
  );
  const wood = info.woodenTotal;
  const lvl = info.lvlTotal;
  const plywood = info.plywoodTotal;
  const interior = info.interiorTotal;
  const exterior = info.exteriorTotal;
  const total = info.totalCost;
  const m3 = info.m3Cost;
  updatedBom.product.woodCost = wood;
  updatedBom.product.lvlCost = lvl;
  updatedBom.product.panelCost = plywood;
  updatedBom.product.interiorCost = interior;
  updatedBom.product.exteriorCost = exterior;
  updatedBom.product.totalCost = total;
  updatedBom.product.m3Cost = m3;
  if (updatableBom.product) {
    updatableBom.product.woodCost = wood;
    updatableBom.product.lvlCost = lvl;
    updatableBom.product.panelCost = plywood;
    updatableBom.product.interiorCost = interior;
    updatableBom.product.exteriorCost = exterior;
    updatableBom.product.totalCost = total;
    updatableBom.product.m3Cost = m3;
  }

  return { updatedBom, updatableBom };
}

interface UpdateBomOutput {
  updatedBom: Bom | undefined;
  updatableBom: UpdateBomInput | undefined;
}

export function updateBom(
  rawData: Bom | undefined,
  inputs: GraphQLInput,
  type: "product" | "component" | "decoration"
): UpdateBomOutput {
  if (!rawData) return { updatedBom: undefined, updatableBom: undefined };
  let updatedBom: Bom = rawData;
  let updatableBom: UpdateBomInput = {
    product: undefined,
    components: [],
    decorations: [],
  };

  if (type === "product") {
    Object.assign(updatableBom, {
      product: inputs,
    });
    updatedBom.product = {
      ...(updatedBom.product as ProductBom),
      ...inputs,
    };
  }
  if (type === "component") {
    updatableBom.components?.push(inputs);

    if (updatedBom.components) {
      // areaIdが一致するcomponentは複数あるので、この形式
      const targets = getIndex(updatedBom.components, inputs.id, true);
      targets.forEach((target) => {
        // areaIdの更新の場合は対象componentのareaNameとareaOrderも更新する
        let updateObj = {};
        if (inputs.areaId) {
          const areaId = inputs.areaId;
          const comp = updatedBom.components?.filter(
            (c) => c.areaId === areaId
          )[0];
          if (!comp) return;
          updateObj = {
            areaName: comp.areaName,
            areaOrder: comp.areaOrder,
          };
        }
        if (updatedBom.components === undefined) return;
        let component = updatedBom.components[target];
        const componentId = component.id;
        if (!component) return;
        component = {
          ...(component as ComponentBom),
          ...inputs,
          id: componentId, // componentIdは上書きすることはないので固定しておく(areaの更新時にareaIdで上書きされかねない)
          ...updateObj,
        };
        updatedBom.components[target] = component;
      });
    }
  }

  if (type === "decoration") {
    updatableBom.decorations?.push(inputs);

    if (updatedBom.interiors) {
      let target =
        updatedBom.interiors[getIndex(updatedBom.interiors, inputs.id)[0]];
      target = {
        ...(target as DecorationBom),
        ...inputs,
      };
      updatedBom.interiors[getIndex(updatedBom.interiors, inputs.id)[0]] =
        target;
    }
    if (updatedBom.exteriors) {
      let target =
        updatedBom.exteriors[getIndex(updatedBom.exteriors, inputs.id)[0]];
      target = {
        ...(target as DecorationBom),
        ...inputs,
      };
      updatedBom.exteriors[getIndex(updatedBom.exteriors, inputs.id)[0]] =
        target;
    }
  }

  return { updatedBom, updatableBom };
}

// 対象となるcomponent(interiorやcomponents以下のcomponent)のindexを返す
// componentId(なくてincludeAreaの指定があればareaIdも)が一致するものを探す(複数返ってくる場合もある)
function getIndex(
  arr: any[],
  id: string,
  includeArea: boolean = false
): number[] {
  const idx: number[] = [];
  arr.forEach((a, i) => {
    if (a.id === id || (includeArea && a.areaId && a.areaId === id))
      idx.push(i);
  });
  return idx;
}

// 任意のproductBomのElementを更新する関数
export async function updateArbitraryProductBomElement(
  productId: string,
  input: GraphQLInput,
  materials: Material[],
  species: Species[],
  group: Group,
  ignoreCase: boolean = false
): Promise<any> {
  // 対象のproductBomとproductを持ってくる
  const [bom, product] = await Promise.all([
    getProductBomByProductId(productId),
    getProductById(productId),
  ]);
  // Elementを再計算
  const { updatableBom } = updateBomElements(
    bom ?? undefined,
    { ...input, id: productId },
    materials,
    species,
    group
  );
  // caseの更新データ作成
  const newProduct = {
    ...product,
    elements: updatableBom?.product?.elements,
  };
  const newCsInput = getUpdatedCaseInputByProductElements(newProduct);
  if (newCsInput !== null && !ignoreCase) {
    // case更新
    await updateProduct({
      id: productId,
      cases: newCsInput,
    });
  }
  // bom更新
  const updatedProduct = await updateProductBom({
    id: product.id,
    ...updatableBom,
  });
  return updatedProduct;
}

// 任意のpackageBomのElementを更新する関数
export async function updateArbitraryPackageBomElement(
  packageId: string,
  input: GraphQLInput,
  materials: Material[],
  species: Species[],
  group: Group
): Promise<any> {
  // 対象のproductBomとproductを持ってくる
  const [bom, product] = await Promise.all([
    getPackageBomByID(packageId),
    getPackageById(packageId),
  ]);
  // Elementを再計算
  const { updatableBom } = updateBomElements(
    bom,
    { ...input, id: packageId },
    materials,
    species,
    group
  );
  // bom更新
  const updatedPackage = await updatePackageBom({
    id: product.id,
    ...updatableBom,
  });
  return updatedPackage;
}
