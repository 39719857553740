// 等分布荷重
export const EqualDistribution = (
  fb: number,
  l: number,
  l1: number,
  l2: number,
  b: number,
  h: number
) => {
  if (!l) {
    return 0;
  }
  return (4 * b * h ** 2 * fb) / (3 * l);
};

// 中央集中荷重
export const Centralization = (
  fb: number,
  l: number,
  l1: number,
  l2: number,
  b: number,
  h: number
) => {
  if (!l) {
    return 0;
  }
  return (2 * b * h ** 2 * fb) / (3 * l);
};

// 偏荷重(集中)
export const Partial = (
  fb: number,
  l: number,
  l1: number,
  l2: number,
  b: number,
  h: number
) => {
  if (!l1 || !l2 || l1 <= l2) {
    return 0;
  }
  return (b * h ** 2 * l * fb) / (6 * l1 * l2);
};

// 2点集中荷重
export const TwoPointsConcentrated = (
  fb: number,
  l: number,
  l1: number,
  l2: number,
  b: number,
  h: number
) => {
  if (!l1 || !l2 || l1 < l2) {
    return 0;
  }
  return (b * h ** 2 * l * fb) / (3 * (l - l1 + l2) * l1);
};

export const Calculate: { [key: string]: any } = {
  EqualDistribution: EqualDistribution,
  Centralization: Centralization,
  Partial: Partial,
  TwoPointsConcentrated: TwoPointsConcentrated,
};
