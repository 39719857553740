import { API, graphqlOperation } from "aws-amplify";

import { Bom } from "./update";

import { getProductBom } from "graphql/queries";
import * as mutations from "graphql/mutations";
import { GraphQLInput } from "hooks/data";

export const getProductBomByProductId = async (
  id: string,
  fromQuoteLine: boolean = false
): Promise<Bom | null> => {
  let bom;
  try {
    bom = (
      await API.graphql(
        graphqlOperation(getProductBom, {
          input: { productId: id, fromQuoteLine },
        })
      )
    ).data.getProductBom;
  } catch (err) {
    // quoteProduct削除(見積梱包初期化後)に、時差で取得できないエラーが発生する
    // そのためmessageにProduct not foundの文字列があったら無視してnullを返す
    if (err instanceof Error) {
      if (err.message.includes("Product not found")) return null;
    }
    // その他のエラーはthrow
    throw err;
  }
  return bom;
};

export const updateProductBom = async (input: GraphQLInput) => {
  const product = (
    await API.graphql(
      graphqlOperation(mutations.updateProductBom, {
        input,
      })
    )
  ).data.updateProductBom;
  return product;
};

export const updatePackageBom = async (input: GraphQLInput) => {
  const product = (
    await API.graphql(
      graphqlOperation(mutations.updatePackageBom, {
        input,
      })
    )
  ).data.updatePackageBom;
  return product;
};
